import { loadView } from "@/router/loadView"

export default [
    //#region 【1】 (病毒檢驗)
    { /* 病毒檢驗 */
        path: '/shrimp-virus',
        name: 'ShrimpVirus',
        component: loadView("VirusManagement/ShrimpVirus"),
        meta: { roles: ['admin'] }  // 需要為 Admin
    },

    {   /* 病毒檢驗(同日紀錄) */
        path: '/shrimp-virus/view/:id',
        name: 'ShrimpVirusView',
        component: loadView("VirusManagement/ShrimpVirus/ShrimpVirusView"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },

    {   /* 病毒檢驗(同排程) */
        path: '/shrimp-virus/schedule/:id',
        name: 'ShrimpVirusSchedule',
        component: loadView("VirusManagement/ShrimpVirus/ShrimpVirusSchedule"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },
    {   /* 病毒檢驗(同苗源) */
        path: '/shrimp-virus/source/:id',
        name: 'ShrimpVirusSource',
        component: loadView("VirusManagement/ShrimpVirus/ShrimpVirusSource"),
        meta: { roles: ['admin'] } // 需要為 Admin
    },
    //#endregion

];