// // 苗源API
// import axios from 'axios'
// const SourceRequest = axios.create({
//     baseURL: '/api/source/'
// })
  
import service from '@/api/service'
const url = "/source"
  
  export const getSource = () => service.get(`${url}/`)
  export const createSource = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleSource = (id) => service.get(`${url}/${id}/`)
  export const updateSource = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })
  export const deleteSource = (id) => service.delete(`${url}/${id}/`)