// // 苗源API
// import axios from 'axios'
// const SeedOutputRequest = axios.create({
//     baseURL: '/api/seed-output/'
// })
  
import service from '@/api/service'
const url = "/seed-output"
  
  export const getSeedOutput = (params) => service.get(`${url}/`, {params})
  export const getSeedOutputVirus = (params) => service.get(`${url}/virus/`, {params})
  export const createSeedOutput = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleSeedOutput = (id) => service.get(`${url}/${id}/`)
  export const updateSeedOutput = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })
  export const deleteSeedOutput = (id) => service.delete(`${url}/${id}/`)