// 先引用 BaseServer取得 CRUD封裝
import BaseService from "@/api/services/BaseService"
import service from '@/api/service';

class ScheduleAnaylsisService extends BaseService{
    constructor(){
        super('analysis') // 帶入 order為 resource
    }

    // 取得獎金分析
    get_bonus(params) {
        return service.get(`/${this.resource}/bonus/`, { params });
    }

    // 取得預測獎金
    get_predict_bonus_by_schedule_id(id, params) {
        return service.get(`/${this.resource}/bonus/${id}/get-predict/`, { params });
    }
}


// 將封裝的 class匯出
export default new ScheduleAnaylsisService()