

import { getAlertFeedInputScheduleStatus, getAlertFeedInputPositions, getAlertFeedInputDates } from './modules/alert_feed_input'
export const apiGetAlertFeedInputScheduleStatus = getAlertFeedInputScheduleStatus
export const apiGetAlertFeedInputPositions = getAlertFeedInputPositions
export const apiGetAlertFeedInputDates = getAlertFeedInputDates


//#region 【品評圖片上傳】
import { uploadFoodTasteImage, importFoodTasteImage, searchImportFoodTasteImage } from "./modules/food/upload/image/taste"
export const apiUploadFoodTasteImage = uploadFoodTasteImage
export const apiImportFoodTasteImage = importFoodTasteImage
export const apiSearchImportFoodTasteImage = searchImportFoodTasteImage

//#endregion

//#region 【蝦色圖片上傳】
import { uploadFoodColorImage, importFoodColorImage, searchImportFoodColorImage } from "./modules/food/upload/image/color"
export const apiUploadFoodColorImage = uploadFoodColorImage
export const apiImportFoodColorImage = importFoodColorImage
export const apiSearchImportFoodColorImage = searchImportFoodColorImage

//#endregion


//#region (RecordManagement)
//#region (水質管理)
import { getWaterManagementList, getWaterManagementDates, getWaterManagementExportSell } from "./modules/breeding_record/water_management"
export const apiGetWaterManagementList = getWaterManagementList
export const apiGetWaterManagementDates = getWaterManagementDates
export const apiGetWaterManagementExportSell = getWaterManagementExportSell
//#endregion


//#endregion







//#region (預期目標值)
//#region 【目標放苗】
import { 
  getExpectedSchedule, getSingleExpectedSchedule, createExpectedSchedule, updateExpectedSchedule, deleteExpectedSchedule
} from "./modules/schedule/expected"
export const apiGetExpectedSchedule = getExpectedSchedule
export const apiGetSingleExpectedSchedule = getSingleExpectedSchedule
export const apiCreateExpectedSchedule = createExpectedSchedule
export const apiUpdateExpectedSchedule = updateExpectedSchedule
export const apiDeleteExpectedSchedule = deleteExpectedSchedule
//#endregion


//#region 【目標收貨】
import { 
  getExpectedSell, getSingleExpectedSell, createExpectedSell, updateExpectedSell, deleteExpectedSell
} from "./modules/sell/expected"
export const apiGetExpectedSell = getExpectedSell
export const apiGetSingleExpectedSell = getSingleExpectedSell
export const apiCreateExpectedSell = createExpectedSell
export const apiUpdateExpectedSell = updateExpectedSell
export const apiDeleteExpectedSell = deleteExpectedSell
//#endregion

//#endregion

//#region (數據調控)




// 新的養殖記錄
import {
  getPositionInput,
  getPositionInputDate,
  getPositionInputUpdateDate,
  getPositionInputPoolDetail,
} from '@/api/modules/record_input/feed_input_v2'
export const apiGetPositionInput  = getPositionInput 
export const apiGetPositionInputDate  = getPositionInputDate 
export const apiGetPositionInputUpdateDate  = getPositionInputUpdateDate 
export const apiGetPositionInputPoolDetail  = getPositionInputPoolDetail 




// #region 【生產管理】
import { 
  getProductManagementList, getProductManagementDetail, getProductDates, getProductYearAnalysis, getProductPackageManagement, getProductPositionManagement
} from "./modules/product/management"
export const apiGetProductManagementList = getProductManagementList // 取得各區域的食品檢測平均結果
export const apiGetProductManagementDetail = getProductManagementDetail // 取得各區域的食品檢測平均結果
export const apiGetProductDates = getProductDates // 取得各區域的食品檢測平均結果
export const apiGetProductYearAnalysis = getProductYearAnalysis // 取得各區域的食品檢測平均結果
export const apiGetProductPackageManagement = getProductPackageManagement // 取得各區域的食品檢測平均結果
export const apiGetProductPositionManagement = getProductPositionManagement // 取得各區域的食品檢測平均結果
//#endregion




//#region 【食品管理】
import { getFoodManagement, getFoodManagementDetail, getFoodManagementDate } from "./modules/food/food_management"
export const apiGetFoodManagement = getFoodManagement
export const apiGetFoodManagementDetail = getFoodManagementDetail
export const apiGetFoodManagementDate = getFoodManagementDate
//#endregion


//#region (收成分析)
import {getHarvestAnalysis} from './modules/harvest_analysis'
export const apiGetHarvestAnalysis = getHarvestAnalysis
//#endregion


//#region 場域地圖
// 區域
import {
  getStationArea, createStationArea, getSingleStationArea, updateStationArea, deleteStationArea
} from './modules/station/areas.js'
export const apiGetStationArea = getStationArea
export const apiGetSingleStationArea = getSingleStationArea
export const apiCreateStationArea = createStationArea
export const apiUpdateStationArea = updateStationArea
export const apiDeleteStationArea = deleteStationArea

// 場
import {
  getStationPosition, createStationPosition, getSingleStationPosition, updateStationPosition, deleteStationPosition,
} from './modules/station/positions'
export const apiGetStationPosition = getStationPosition
export const apiGetSingleStationPosition = getSingleStationPosition
export const apiCreateStationPosition = createStationPosition
export const apiUpdateStationPosition = updateStationPosition
export const apiDeleteStationPosition = deleteStationPosition

// 水文資訊
import {
  getStationWater, getStationWaterHistory
} from './modules/station/waters'
export const apiGetStationWater = getStationWater
export const apiGetStationWaterHistory = getStationWaterHistory

// 池
import {
  getStationPool, getSingleStationPool
} from './modules/station/pools'
export const apiGetStationPool = getStationPool
export const apiGetSingleStationPool = getSingleStationPool
//#endregion


//#region (場域資訊 - info)
import {getArea, getSingleArea, createArea, updateArea, deleteArea} from './modules/infos/area'
export const apiGetArea = getArea
export const apiGetSingleArea = getSingleArea
export const apiCreateArea = createArea
export const apiUpdateArea = updateArea
export const apiDeleteArea = deleteArea

//#endregion


//#region (排程 + 紀錄[非Input])
/* 養殖排程 */
import {getSchedule, getScheduleAnalysis, getScheduleAnalysisHistory, getScheduleAnalysisGrow, getScheduleAnalysisBonus, getScheduleCatch, getSingleSchedule, createSchedule, createBatchSchedule, updateSchedule, deleteSchedule} from './modules/feed_schedule'
export const apiGetSchedule = getSchedule
export const apiGetScheduleCatch = getScheduleCatch
export const apiGetScheduleAnalysis = getScheduleAnalysis
export const apiGetScheduleAnalysisHistory = getScheduleAnalysisHistory
export const apiGetScheduleAnalysisGrow = getScheduleAnalysisGrow
export const apiGetScheduleAnalysisBonus = getScheduleAnalysisBonus
export const apiGetSingleSchedule = getSingleSchedule
export const apiCreateSchedule = createSchedule
export const apiCreateBatchSchedule = createBatchSchedule
export const apiUpdateSchedule = updateSchedule
export const apiDeleteSchedule = deleteSchedule

//#endregion

//#region (小排程處理 - Output Input)
import {getOutsideInput,
  getSingleOutsideInput,
  createOutsideInput,
  updateOutsideInput,
  deleteOutsideInput,} from './modules/outside-input'
export const apiGetOutsideInput = getOutsideInput
export const apiGetSingleOutsideInput = getSingleOutsideInput
export const apiCreateOutsideInput = createOutsideInput
export const apiUpdateOutsideInput = updateOutsideInput
export const apiDeleteOutsideInput = deleteOutsideInput

//#endregion



/* 養殖日誌紀錄(輸入模式) */
import {getRecordInput, getSingleRecordInput, createRecordInput, updateRecordInput, updateScheduleEmpty, deleteRecordInput} from './modules/record_input/record_input'
export const apiGetRecordInput = getRecordInput
export const apiGetSingleRecordInput = getSingleRecordInput
export const apiCreateRecordInput = createRecordInput
export const apiUpdateRecordInput = updateRecordInput
export const apiUpdateScheduleEmpty = updateScheduleEmpty
export const apiDeleteRecordInput = deleteRecordInput

/* 養殖日誌水質紀錄(輸入模式) */
import {getWaterRecordInput, getSingleWaterRecordInput, createWaterRecordInput, updateWaterRecordInput, deleteWaterRecordInput} from './modules/record_input/water_record_input'
export const apiGetWaterRecordInput = getWaterRecordInput
export const apiGetSingleWaterRecordInput = getSingleWaterRecordInput
export const apiCreateWaterRecordInput = createWaterRecordInput
export const apiUpdateWaterRecordInput = updateWaterRecordInput
export const apiDeleteWaterRecordInput = deleteWaterRecordInput

/* 每日記事紀錄(輸入模式) */
import {createDailyRecordImage, deleteDailyRecordImage, getDailyRecordList, getDailyRecordInput, getSingleDailyRecordInput, createDailyRecordInput, updateDailyRecordInput, deleteDailyRecordInput} from './modules/record_input/daily_record_input'
export const apiGetDailyRecordList = getDailyRecordList
export const apiGetDailyRecordInput = getDailyRecordInput
export const apiGetSingleDailyRecordInput = getSingleDailyRecordInput
export const apiCreateDailyRecordInput = createDailyRecordInput
export const apiUpdateDailyRecordInput = updateDailyRecordInput
export const apiDeleteDailyRecordInput = deleteDailyRecordInput

export const apiCreateDailyRecordImage = createDailyRecordImage
export const apiDeleteDailyRecordImage = deleteDailyRecordImage


/* 空池管理(輸入模式) */
import {getEmptyRecordInput, getSingleEmptyRecordInput, createEmptyRecordInput, updateEmptyRecordInput, deleteEmptyRecordInput} from './modules/empty/empty_record_input'
export const apiGetEmptyRecordInput = getEmptyRecordInput
export const apiGetSingleEmptyRecordInput = getSingleEmptyRecordInput
export const apiCreateEmptyRecordInput = createEmptyRecordInput
export const apiUpdateEmptyRecordInput = updateEmptyRecordInput
export const apiDeleteEmptyRecordInput = deleteEmptyRecordInput

// #endregion


//#region (報表管理(月報表))
// 收成月報表
// 養殖管理
// 空池管理
import {getMonthReport, getMonthReportExport, getFeedManage, getEmptyManage} from './modules/report_manage'
export const apiGetMonthReport = getMonthReport
export const apiGetMonthReportExport = getMonthReportExport
export const apiGetFeedManage = getFeedManage
export const apiGetEmptyManage = getEmptyManage


/* 空池管理(管理者模式) */
import {getEmptyManageList, getEmptyManageDetail, createEmptyManage, patchEmptyManage} from './modules/empty/empty_manage'
export const apiGetEmptyManageList = getEmptyManageList
export const apiGetEmptyManageDetail = getEmptyManageDetail
export const apiCreateEmptyManage = createEmptyManage
export const apiPatchEmptyManage = patchEmptyManage

/* 日報表 */
import {getDailyReport} from './modules/daily_report'
export const apiGetDailyReport = getDailyReport
//#endregion






//#region 【檔案管理】
import { downloadFile, exportExcelFile} from './modules/file'
export const apiDownloadFile = downloadFile
export const apiExportExcelFile = exportExcelFile

//#endregion


//#region (芽廠 + 芽排程)
/* 芽廠 */
import {getSproutFactory, getSingleSproutFactory, createSproutFactory, updateSproutFactory, deleteSproutFactory} from './modules/sprout/factory'
export const apiGetSproutFactory = getSproutFactory
export const apiGetSingleSproutFactory = getSingleSproutFactory
export const apiCreateSproutFactory = createSproutFactory
export const apiUpdateSproutFactory = updateSproutFactory
export const apiDeleteSproutFactory = deleteSproutFactory

/* 芽排程 */
import {getSproutSchedule, getSingleSproutSchedule, createSproutSchedule, updateSproutSchedule, deleteSproutSchedule} from './modules/sprout/schedule'
export const apiGetSproutSchedule = getSproutSchedule
export const apiGetSingleSproutSchedule = getSingleSproutSchedule
export const apiCreateSproutSchedule = createSproutSchedule
export const apiUpdateSproutSchedule = updateSproutSchedule
export const apiDeleteSproutSchedule = deleteSproutSchedule
//#endregion

/* 芽<->出苗排程 */
import {getSeedOutput, getSeedOutputVirus, getSingleSeedOutput, createSeedOutput, updateSeedOutput, deleteSeedOutput} from './modules/seed/seed_output'
export const apiGetSeedOutput = getSeedOutput
export const apiGetSeedOutputVirus = getSeedOutputVirus
export const apiGetSingleSeedOutput = getSingleSeedOutput
export const apiCreateSeedOutput = createSeedOutput
export const apiUpdateSeedOutput = updateSeedOutput
export const apiDeleteSeedOutput = deleteSeedOutput

//#region (種源&苗源)
/* 苗源排程 */
import {getSeedSchedule, getSeedScheduleDate, getSeedScheduleDetail, getSeedScheduleFeedsDetail, getSingleSeedSchedule, createSeedSchedule, updateSeedSchedule, deleteSeedSchedule} from './modules/seed/schedule'
export const apiGetSeedSchedule = getSeedSchedule
export const apiGetSeedScheduleDate = getSeedScheduleDate
export const apiGetSeedScheduleDetail = getSeedScheduleDetail
export const apiGetSeedScheduleFeedsDetail = getSeedScheduleFeedsDetail
export const apiGetSingleSeedSchedule = getSingleSeedSchedule
export const apiCreateSeedSchedule = createSeedSchedule
export const apiUpdateSeedSchedule = updateSeedSchedule
export const apiDeleteSeedSchedule = deleteSeedSchedule

/* 種源紀錄 */
import {getSource, getSingleSource, createSource, updateSource, deleteSource} from './modules/seed/source'
export const apiGetSource = getSource
export const apiGetSingleSource = getSingleSource
export const apiCreateSource = createSource
export const apiUpdateSource = updateSource
export const apiDeleteSource = deleteSource

/* 苗廠紀錄 */
import {getSeedFactory, getSingleSeedFactory, createSeedFactory, updateSeedFactory, deleteSeedFactory} from './modules/seed/factory'
export const apiGetSeedFactory = getSeedFactory
export const apiGetSingleSeedFactory = getSingleSeedFactory
export const apiCreateSeedFactory = createSeedFactory
export const apiUpdateSeedFactory = updateSeedFactory
export const apiDeleteSeedFactory = deleteSeedFactory

/* 苗池紀錄 */
import {getSeedPool, getSingleSeedPool, createSeedPool, updateSeedPool, deleteSeedPool} from './modules/seed/pool'
export const apiGetSeedPool = getSeedPool
export const apiGetSingleSeedPool = getSingleSeedPool
export const apiCreateSeedPool = createSeedPool
export const apiUpdateSeedPool = updateSeedPool
export const apiDeleteSeedPool = deleteSeedPool

/* 苗池排程紀錄 */
import {getSeedPoolSchedule, getSingleSeedPoolSchedule, bulkCreateSeedPoolSchedule, createSeedPoolSchedule, updateSeedPoolSchedule, deleteSeedPoolSchedule} from './modules/seed/pool_schedule'
export const apiGetSeedPoolSchedule = getSeedPoolSchedule
export const apiGetSingleSeedPoolSchedule = getSingleSeedPoolSchedule
export const apiBulkCreateSeedPoolSchedule = bulkCreateSeedPoolSchedule
export const apiCreateSeedPoolSchedule = createSeedPoolSchedule
export const apiUpdateSeedPoolSchedule = updateSeedPoolSchedule
export const apiDeleteSeedPoolSchedule = deleteSeedPoolSchedule
//#endregion



//#region (出貨紀錄)
import {getShrimpSell, getShrimpSellGetPlaces, getShrimpSellGetDates, getSingleShrimpSell, createShrimpSell, updateShrimpSell, deleteShrimpSell} from './modules/sell/real'
export const apiGetShrimpSell = getShrimpSell
export const apiGetShrimpSellPlaces = getShrimpSellGetPlaces
export const apiGetShrimpSellDates = getShrimpSellGetDates
export const apiGetSingleShrimpSell = getSingleShrimpSell
export const apiCreateShrimpSell = createShrimpSell
export const apiUpdateShrimpSell = updateShrimpSell
export const apiDeleteShrimpSell = deleteShrimpSell
//#endregion




//#region (上傳食品檢驗 Excel)
//#region (甜度)
import { uploadSweetExcel, importSweetExcel, searchImportSweetExcel} from './modules/food/upload/excel/sweet'
export const apiUploadSweetExcel = uploadSweetExcel
export const apiImportSweetExcel = importSweetExcel
export const apiSearchImportSweetExcel = searchImportSweetExcel
//#endregion

//#region (蝦色)
// [版本1]
import { uploadColorExcel_v1, importColorExcel_v1} from './modules/food/upload/excel/color/v1'
export const apiUploadColorV1Excel = uploadColorExcel_v1
export const apiImportColorV1Excel = importColorExcel_v1

// [版本2]
import { uploadColorExcel_v2, importColorExcel_v2, searchImportColorExcel} from './modules/food/upload/excel/color/v2'
export const apiUploadColorV2Excel = uploadColorExcel_v2
export const apiImportColorV2Excel = importColorExcel_v2
export const apiSearchImportColorExcel = searchImportColorExcel
//#endregion

//region 【肥滿度】
import {
  uploadFullnessExcel, importFullnessExcel, 
} from './modules/food/upload/excel/fullness'
export const apiUploadFullnessExcel = uploadFullnessExcel
export const apiImportFullnessExcel = importFullnessExcel
//#endregion

//#region (冷凍蝦)
import { uploadIceExcel, importIceExcel, searchImportIceExcel} from './modules/food/upload/excel/ice'
export const apiUploadIceExcel = uploadIceExcel
export const apiImportIceExcel = importIceExcel
export const apiSearchImportIceExcel = searchImportIceExcel
//#endregion


//#endregion


//#region (食品檢驗)
// 找尋生產排程日期
import { getFoodProductScheduleDates } from './modules/food/food_product_schedule'
export const apiGetFoodProductScheduleDates = getFoodProductScheduleDates

// 找尋生產場域
import { getFoodProductPosition, bulkUpdateFoodProductPosition } from './modules/food/food_product_position'
export const apiGetFoodProductPosition = getFoodProductPosition
export const apiBulkUpdateFoodProductPosition = bulkUpdateFoodProductPosition













//#region (其他)
/* 各鄉鎮區資料 */
import {getTaiwanRegions, getSingleTaiwanRegions} from './modules/taiwan_regions'
export const apiGetTaiwanRegions = getTaiwanRegions
export const apiGetSingleTaiwanRegions = getSingleTaiwanRegions

/* 水質檢驗 */
import {getWater} from './modules/water'
export const apiGetWater = getWater

/* 溫度 */
import {getTempStation, getTempUpdateGrow, getTempStandardGrow, getStation} from './modules/temp'
export const apiGetTempStation = getTempStation
export const apiGetTempUpdateGrow = getTempUpdateGrow
export const apiGetTempStandardGrow = getTempStandardGrow
export const apiGetStation = getStation
//#endregion



//#endregion