// import axios from 'axios'
// const Instance = axios.create({
//     baseURL: '/api/seed-pool-schedule/'
// })

import service from '@/api/service'
const url = '/seed-pool-schedule' // /api/temp

  
  export const getSeedPoolSchedule = (params) => service.get(`${url}/`, {params})
  export const bulkCreateSeedPoolSchedule = (data) => service.post(`${url}/`, data, )
  export const createSeedPoolSchedule = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleSeedPoolSchedule = (id, params) => service.get(`${url}/${id}/`, {params})
  export const updateSeedPoolSchedule = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })
  export const deleteSeedPoolSchedule = (id) => service.delete(`${url}/${id}/`)