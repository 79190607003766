// // 苗源API
// import axios from 'axios'
// const SeedFactoryRequest = axios.create({
//     baseURL: '/api/seed-factory/' // 前綴 URL
// })
  
import service from '@/api/service'
const url = '/seed-factory' // /api/temp

  
  export const getSeedFactory = (params) => service.get(`${url}/`, {params})
  export const createSeedFactory = (data) => service.post(`${url}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })

  export const getSingleSeedFactory = (id, params) => service.get(`${url}/${id}/`, {params})
  export const updateSeedFactory = (id, data) => service.put(`${url}/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
  })
  export const deleteSeedFactory = (id) => service.delete(`${url}/${id}/`)