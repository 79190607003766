import { loadView } from "@/router/loadView"
export default [
    //#region 【1】 (養殖日誌)
    {   /* 養殖日誌 */
        path: '/feed-diary',
        name: 'FeedDiary',
        component: loadView("BreedingManagement/FeedDiary/FeedDiary"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] } // 需要為 Admin或 Manager
    },
    {   /* 養殖日誌(單一排程) */
        path: '/feed-diary/view/:id',
        name: 'FeedDiaryView',
        component: loadView("BreedingManagement/FeedDiary/FeedDiaryView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] } // 需要為 Admin或 Manager
    },
    //#endregion

    //#region 【2】 (日報表)
    {
        path: '/daily-report',
        component: loadView("BreedingManagement/DailyReport/DailyReport"),
        meta: {
            roles: ['admin', 'manager', 'team_leader']
        }, // 需要為 Admin或 Manager
        name: 'DailyReport',
        redirect: {
            name: 'DailyReportView',
            params: {  // 如果沒ID，則為'P001'
                id: sessionStorage.getItem("daily_id") || 'P001'
            }
        },
        children: [
            {
                path: 'view/:id',
                name: 'DailyReportView',
                component: loadView("BreedingManagement/DailyReport/DailyReportView"),
                props: true,
                meta: {
                    roles: ['admin', 'manager', 'team_leader']
                }, // 需要為 Admin或 Manager
            },
        ],
    },
    //#endregion

    //#region 【3】 (餵食量管理)
    {
        path: '/feed-management',
        name: 'FeedManageView',
        component: loadView("BreedingManagement/FeedManageView"),
        meta: { roles: ['admin', 'manager', 'team_leader'], keepAlive: true } // 需要為 Admin
    },

    //#endregion

    //#region 【4】 (空池管理)
    {
        path: '/empty-management',
        name: 'EmptyManageView',
        component: loadView("BreedingManagement/EmptyManageView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'], keepAlive: true } // 需要為 Admin
    },
    //#endregion

    //#region 【4】 (移池管理)
    {
        path: '/transfer-pool-management',
        name: 'TransferPoolManageView',
        component: loadView("BreedingManagement/TransferPoolManageView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'], keepAlive: true } // 需要為 Admin
    },
    //#region 【5】 (叫料管理)
    {
        path: '/material-management',
        name: 'MaterialManageView',
        component: loadView("BreedingManagement/MaterialManageView"),
        meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] }, // 需要為 Admin
        redirect: { name: 'MaterialManageTotalView' },
        children: [
            { 
                path: 'total', 
                name: 'MaterialManageTotalView', 
                meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] }, // 需要為 Admin
                component: loadView("BreedingManagement/MaterialManage/MaterialManageTotalView"),
            },
            { 
                path: 'item/:id', 
                name: 'MaterialManageItemView', 
                meta: { roles: ['admin', 'manager', 'team_leader', 'accountant'] }, // 需要為 Admin
                component: loadView("BreedingManagement/MaterialManage/MaterialManageItemView"),
            },
          
        ]
    },
    //#endregion
];